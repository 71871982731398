// extracted by mini-css-extract-plugin
export var ArtistDescription = "Fab5-module--ArtistDescription--6-S+n";
export var ArtistInfos = "Fab5-module--ArtistInfos--1WbFS";
export var ButtonWrapper = "Fab5-module--ButtonWrapper --FcE74";
export var CardWrapper = "Fab5-module--CardWrapper--UrrwH";
export var CarrouselWrapper2 = "Fab5-module--CarrouselWrapper2--gqD6k";
export var Citations = "Fab5-module--Citations--NVtPV";
export var DescriptionWrapper = "Fab5-module--DescriptionWrapper--IeO+l";
export var ImageWrapper = "Fab5-module--ImageWrapper--0vIuF";
export var LinkWrapper = "Fab5-module--LinkWrapper--odUxV";
export var MobileProtrait = "Fab5-module--MobileProtrait--eEGQ4";
export var More = "Fab5-module--More--Byhia";
export var MoreButton = "Fab5-module--MoreButton--xKtX1";
export var NameWrapper = "Fab5-module--NameWrapper--xXdXE";
export var PdpWrapper = "Fab5-module--PdpWrapper--5SXPf";
export var PhotosWrapper = "Fab5-module--PhotosWrapper--Pnpp8";
export var ProfilWrapper = "Fab5-module--ProfilWrapper--thYgZ";
export var TitleWrapper = "Fab5-module--TitleWrapper--1ioql";
export var Wrapper = "Fab5-module--Wrapper--mFNw4";