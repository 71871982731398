
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Fab5.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Fab5Photo1 from "../../../../res/Photos site/Fab 5/6.webp"
import Fab5Photo2 from "../../../../res/Photos site/Fab 5/Graff Fab 5.jpg"
import Fab5Photo3 from "../../../../res/Photos site/Fab 5/4.jpg"
import Fab5Photo4 from "../../../../res/Photos site/Fab 5/5.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/fab5/portrait.jpg'
import PastPresent1 from "../../../../res/fab5/past-present-1.jpg"
import PastPresent2 from "../../../../res/fab5/past-present-2.jpg"
import PastPresent3 from "../../../../res/fab5/past-present-3.jpg"
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Fab5",
  name: "Fab5",
  description:"Fred Brathwaite, né le 31 août 1959, plus connu sous le nom de Fab 5 Freddy est un artiste visuel, cinéaste et pionnier du hip-hop américain. Il est considéré comme l'un des fondateurs du mouvement du graffiti. Freddy a émergé sur la scène créative underground du centre-ville de New York à la fin des années 1970 en tant que graffeur. Il assurait le lien entre la scène rap en plein essor des quartiers chics et la scène artistique No Wave du centre-ville. À la fin des années 1970, Freddy est devenu membre du groupe de graffitis basé à Brooklyn : The Fabulous 5, connu pour avoir peint des whole trains du métropolitain new-yorkais. Avec l'autre membre de Fabulous 5, Lee Quiñones, ils ont commencé à passer du graffiti de rue puis à transiter vers le monde de l'art plus conventionnel et en 1979, ils ont tous deux exposé dans une galerie italienne prestigieuse à Rome la Galleria La Medusa. En 1980, il peint une rame de métro avec des représentations de style bande dessinée de boîtes de soupe géantes de Campbell en clin d'oeil à l’artiste contemporain Andy Warhol. Il apparaît en 1981 dans le clip Rapture lorsque Debbie Harry a rappé que \"Fab 5 Freddy m'a dit que tout le monde vole.\" Il avait également joué dans la fiction Wild style aux côtés de Lady Pink et Lee Quinones représentant la vie de ces jeunes graffeurs. À la fin des années 1980, Freddy est devenu le premier animateur de l\'émission révolutionnaire de vidéoclips hip-hop Yo! MTV Raps.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Fab5.",
  citations: [
    "« People actually perceived me with being this cat from the Bronx because I\'m one of a handful of folks that was actually acting in 'Wild Style’ »"
  ],
  photos: [
    { src: PastPresent1, name: "Fab5" },
    { src: PastPresent2, name: "Fab5" },
    { src: PastPresent3, name: "Fab5" },
  ],
};
const Fab5 = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>Fab 5 Freddy <h2 style={{paddingLeft: "16px"}}>(Fred Brathwaite - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1959</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Fab5;